.control-group {
	margin: 0.5em 0;
	padding: 0.5em 0;

	border-left: 0.5ch solid var(--brand-color-secondary);

	background-color: var(--brand-color-lightest);
}

.control-group__hint {
	margin: 0 0.5em;
	padding: 0;
	font-size: small;
}

.control-group__error-message {
	display: none;
	color: var(--brand-color-danger);
	font-weight: bold;
}

.control-group--error .control-group__error-message {
	display: block;
}
