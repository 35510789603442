.announcement {
  display: flex;
  margin-bottom: 1em;
}

.announcement__block {
  margin: auto;
  background-color: var(--brand-color-secondary-light);
  padding: 1em;
  border-radius: 1em;
  text-align: center;
}
