.nav-header {
  font-size: 1.11rem;
  line-height: 1.1;
  margin-bottom: 30px;

  --gradient-padding: calc((130px - 105px) / 2);

  background-image: url(../img/nav-bg.jpg),
    linear-gradient(
      to bottom,
      transparent 0,
      transparent var(--gradient-padding),
      var(--brand-color) var(--gradient-padding),
      var(--brand-color) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      to top,
      transparent 0,
      transparent var(--gradient-padding),
      var(--brand-color) var(--gradient-padding),
      var(--brand-color) 50%,
      transparent 50%,
      transparent
    );
  background-repeat: no-repeat;
  background-position: center center;
}

.nav-header__items {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
}

.nav-header__item {
  color: white;
  padding: 0;
  flex-grow: 1;
  flex-basis: 0;
  transition: transform 0.1s ease-out;

  --link-padding: 30px;
}

.nav-header__item .nav-header__link {
  padding-left: var(--link-padding);
}

.nav-header__item:last-child .nav-header__link {
  padding-right: var(--link-padding);
}

.nav-header__icon {
  fill: currentColor;
  // aspect-ratio: attr(width) / attr(height);
  height: 2em;
  width: auto;
}

.nav-header__caption {
  margin-top: 0;
}

.nav-header__link {
  padding-top: 41px;
  padding-bottom: 41px;
  text-decoration: none;
  color: inherit;
  display: grid;
  grid-template-columns: min-content min-content;
  column-gap: 12px;
  align-items: center;

  margin: 3px;
}

.nav-header__item:active {
  transform: scale(0.95);
}

.nav-header__item:hover,
.nav-header__item:focus-within {
  background-color: white;
  background-image: url(../img/nav-active-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: var(--brand-color);
}

@media (max-width: @width-desktop) {
  .nav-header {
    background-image: none;
  }

  .nav-header__items {
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    gap: 10px;
  }

  .nav-header__item {
    --link-padding: 5px;
    flex-basis: 0;
    flex-grow: 0;
    flex-shrink: 0;
    height: auto;

    background-color: var(--brand-color);
  }

  .nav-header__link {
    padding: var(--link-padding);

    width: calc(100% - 6px);
    height: calc(100% - 6px);

    margin: 3px;
  }
}
