@page-padding: 5px;

.page {
  min-width: @width-mobile;

  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows:
    min-content
    min-content
    auto
    min-content
    min-content;
  grid-template-areas:
    "header"
    "nav-header"
    "main"
    "nav-footer"
    "footer";
}

.header {
  grid-area: header;
}

.nav-header {
  grid-area: nav-header;
}

.main {
  grid-area: main;
  width: 100%;
}

.nav-footer {
  grid-area: nav-footer;
}

.footer {
  grid-area: footer;
}

.container {
  --container-width: min(960px, 100%);
  width: 100%;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.container--wide {
  max-width: min(calc(var(--container-width) + 60px), calc(960px + 60px));
}

@media (max-width: @width-tablet) {
  .page__body {
    grid-template-rows:
      min-content
      min-content
      min-content
      auto
      min-content;
    grid-template-areas:
      "header"
      "nav-footer"
      "nav-header"
      "main"
      "footer";
  }
}
