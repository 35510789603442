.button {
  color: var(--brand-color);
  background: none;
  padding: 1em 2em;
  border: 1px solid currentColor;
  transition: transform 0.1s ease-out;
}

.button:disabled,
.button--disabled {
  color: var(--brand-color-light);
  background-color: var(--brand-color-grey);
}

.button:active,
.button--active {
  transform: scale(0.95);
}

.button:hover:not(:disabled),
.button--hover {
  background-color: var(--brand-color-light);
  color: var(--brand-color-white);
}

.button--primary {
  background-color: var(--brand-color);
  color: var(--brand-color-white);
}

.button--primary:disabled {
  color: var(--brand-color);
  background-color: var(--brand-color-dark);
}

.button--primary:hover:not(:disabled),
.button--primary.button--hover,
.button--primary:focus,
.button--primary.button--focus {
  background-color: var(--brand-color-lighter);
  color: var(--brand-color-white);
}

.button--submiting,
.button--submiting:hover:not(:disabled),
.button--submiting.button--hover,
.button--submiting:focus,
.button--submiting.button--focus {
  animation: blinds 2s linear infinite;
  background-position: 0 0;
  background-size: 5ch 1em;
  background-repeat: repeat-x repeat-y;
  background-image: linear-gradient(
    to right,
    var(--brand-color) 0%,
    var(--brand-color-secondary) 50%,
    var(--brand-color) 100%);
  color: white;
}
@keyframes blinds {
  100% {
    background-position: 5ch 0;
  }
}


.button--secondary {
  background-color: var(--brand-color-secondary);
  color: var(--brand-color-white);
}

.button--secondary:disabled {
  color: var(--brand-color-secondary);
  background-color: var(--brand-color-secondary-dark);
}

.button--secondary:hover:not(:disabled),
.button--secondary.button--hover,
.button--secondary:focus,
.button--secondary.button--focus {
  background-color: var(--brand-color-secondary-lighter);
  color: var(--brand-color-white);
}
