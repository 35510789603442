@font-face {
  font-family: "PT Sans";
  src: local("PT Sans"), url("/fonts/PTS55F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  src: local("PT Sans Italic"), url("/fonts/PTS56F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: bold;
  src: local("PT Sans Bold"), url("/fonts/PTS75F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: bold;
  src: local("PT Sans Bold Italic"), url("/fonts/PTS76F_W.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "PT Sans Caption";
  src: local("PT Sans Caption"), url("/fonts/PTC55F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans Caption";
  font-weight: bold;
  src: local("PT Sans Caption Bold"), url("/fonts/PTC75F_W.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "PT Sans Narrow";
  src: local("PT Sans Narrow"), url("/fonts/PTN57F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans Narrow";
  font-weight: bold;
  src: local("PT Sans Narrow Bold"), url("/fonts/PTN77F_W.woff") format("woff");
  font-display: swap;
}
