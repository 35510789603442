*,
*::before,
*::after {
  box-sizing: inherit;
}

[hidden] {
  display: none !important;
}

.noscript {
  position: fixed;
  padding: 1ch;
  text-align: center;
  color: red;
  --noscript-text-shadow-color: white;
  text-shadow: -1px 0 0 var(--noscript-text-shadow-color),
    0 -1px 0 var(--noscript-text-shadow-color),
    1px 0 0 var(--noscript-text-shadow-color),
    0 1px 0 var(--noscript-text-shadow-color);
  left: 10px;
  top: 10px;
  width: 20ch;
  background-color: rgba(255, 200, 200, 0.8);
  border: 1px solid red;
}

html {
  box-sizing: border-box;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.2;

  --brand-color: #0c98d9;
  --brand-color-lighter: #45b5ec;
  --brand-color-light: #71c4ec;
  --brand-color-lightest: rgb(225 244 255);

  --brand-color-darker: #2f7da3;
  --brand-color-dark: #04608d;

  --brand-color-secondary: #ff8902;
  --brand-color-secondary-lighter: #ffa741;
  --brand-color-secondary-light: #ffbe74;
  --brand-color-secondary-darker: #bf7d31;
  --brand-color-secondary-dark: #a65901;

  --brand-color-white: white;
  --brand-color-grey: lightgrey;
  --brand-color-black: #444;

  --brand-color-danger: red;

  // https://colorscheme.ru/#3s11TvNO0w0w0

  color: var(--brand-color-black);
  background-color: var(--brand-color-white);
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

details summary {
  cursor: pointer;
}

@media (max-width: @width-tablet) {
  html {
    font-size: 14px;
  }
}

time {
  white-space: nowrap;
}
