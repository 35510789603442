a.link,
a.instagram,
a.word,
a.pdf,
a[href^="tel:"],
a[href^="https://wa.me/"],
a[href^="mailto:"] {
  text-decoration: none;
  color: var(--brand-color);
}
a[href^="tel:"],
a[href^="https://wa.me/"],
a[href^="mailto:"] {
  white-space: nowrap;
}
a[href^="tel:"]::before,
a[href^="mailto:"]::before {
  font-family: "Segoe UI Emoji", emoji, sans-serif;
  display: inline-block;
  text-align: center;
  width: 3ch;
}
a[href^="tel:"]::before {
  content: "📱";
}
a[href^="https://wa.me/"]::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.7ch;
  margin-left: 0.5ch;
  background-image: url("/img/icons/whatsapp.svg");
  background-size: 100% 100%;
}
a[href^="mailto:"]::before {
  content: "📧";
}
a[href^="tel:+742"]::before {
  content: "📞";
  content: "☏";
  content: "☎";
}
a[href^="tel:"].fax::before {
  content: "📠";
}
a.word::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.7ch;
  /* background-color: red; */
  background-image: url("/img/icons/word.svg");
  background-size: 100% 100%;
}
a.pdf::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.7ch;
  /* background-color: red; */
  background-image: url("/img/icons/pdf.svg");
  background-size: 100% 100%;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
.noscript {
  position: fixed;
  padding: 1ch;
  text-align: center;
  color: red;
  --noscript-text-shadow-color: white;
  text-shadow: -1px 0 0 var(--noscript-text-shadow-color), 0 -1px 0 var(--noscript-text-shadow-color), 1px 0 0 var(--noscript-text-shadow-color), 0 1px 0 var(--noscript-text-shadow-color);
  left: 10px;
  top: 10px;
  width: 20ch;
  background-color: rgba(255, 200, 200, 0.8);
  border: 1px solid red;
}
html {
  box-sizing: border-box;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.2;
  --brand-color: #0c98d9;
  --brand-color-lighter: #45b5ec;
  --brand-color-light: #71c4ec;
  --brand-color-lightest: #e1f4ff;
  --brand-color-darker: #2f7da3;
  --brand-color-dark: #04608d;
  --brand-color-secondary: #ff8902;
  --brand-color-secondary-lighter: #ffa741;
  --brand-color-secondary-light: #ffbe74;
  --brand-color-secondary-darker: #bf7d31;
  --brand-color-secondary-dark: #a65901;
  --brand-color-white: white;
  --brand-color-grey: lightgrey;
  --brand-color-black: #444;
  --brand-color-danger: red;
  color: var(--brand-color-black);
  background-color: var(--brand-color-white);
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
details summary {
  cursor: pointer;
}
@media (max-width: 767.98px) {
  html {
    font-size: 14px;
  }
}
time {
  white-space: nowrap;
}
.page {
  min-width: 320px;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content auto min-content min-content;
  grid-template-areas: "header" "nav-header" "main" "nav-footer" "footer";
}
.header {
  grid-area: header;
}
.nav-header {
  grid-area: nav-header;
}
.main {
  grid-area: main;
  width: 100%;
}
.nav-footer {
  grid-area: nav-footer;
}
.footer {
  grid-area: footer;
}
.container {
  --container-width: min(960px, 100%);
  width: 100%;
  max-width: var(--container-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
.container--wide {
  max-width: min();
}
@media (max-width: 767.98px) {
  .page__body {
    grid-template-rows: min-content min-content min-content auto min-content;
    grid-template-areas: "header" "nav-footer" "nav-header" "main" "footer";
  }
}
.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
@font-face {
  font-family: "PT Sans";
  src: local("PT Sans"), url("/fonts/PTS55F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  src: local("PT Sans Italic"), url("/fonts/PTS56F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: bold;
  src: local("PT Sans Bold"), url("/fonts/PTS75F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: bold;
  src: local("PT Sans Bold Italic"), url("/fonts/PTS76F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans Caption";
  src: local("PT Sans Caption"), url("/fonts/PTC55F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans Caption";
  font-weight: bold;
  src: local("PT Sans Caption Bold"), url("/fonts/PTC75F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans Narrow";
  src: local("PT Sans Narrow"), url("/fonts/PTN57F_W.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "PT Sans Narrow";
  font-weight: bold;
  src: local("PT Sans Narrow Bold"), url("/fonts/PTN77F_W.woff") format("woff");
  font-display: swap;
}
.h1,
.h2,
.h3,
.h4,
.h5 {
  color: var(--brand-color);
  font-weight: bold;
}
.table {
  border-collapse: collapse;
  width: 100%;
  page-break-inside: auto;
}
.table tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
.table th,
.table td {
  padding: 0.5ch;
}
.table thead:first-of-type tr th {
  border-bottom: 1px solid grey;
}
.table tbody tr th,
.table tbody tr td {
  border-bottom: 1px solid lightgray;
}
.table--background {
  background-color: rgba(255, 255, 255, 0.4);
  border: 1ch solid transparent;
}
.table--background caption {
  background-color: rgba(255, 255, 255, 0.4);
  padding-top: 1ch;
}
.table thead:first-of-type tr th {
  border-bottom: 1px solid grey;
}
.table--accent tbody tr th,
.table--accent tbody tr td {
  border-bottom: 1px solid var(--brand-color);
}
.table--accent thead:first-of-type tr th {
  border-bottom: 1px solid var(--brand-color);
}
.blink_me {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
.grid12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.grid12__col {
  grid-column: span 12;
}
.grid12__col--12 {
  grid-column: span 12;
}
.grid12__col--11 {
  grid-column: span 11;
}
.grid12__col--10 {
  grid-column: span 10;
}
.grid12__col--9 {
  grid-column: span 9;
}
.grid12__col--8 {
  grid-column: span 8;
}
.grid12__col--7 {
  grid-column: span 7;
}
.grid12__col--6 {
  grid-column: span 6;
}
.grid12__col--5 {
  grid-column: span 5;
}
.grid12__col--4 {
  grid-column: span 4;
}
.grid12__col--3 {
  grid-column: span 3;
}
.grid12__col--2 {
  grid-column: span 2;
}
.grid12__col--1 {
  grid-column: span 1;
}
@media (min-width: 767.98px) {
  .grid12__desktop-col--12 {
    grid-column: span 12;
  }
  .grid12__desktop-col--11 {
    grid-column: span 11;
  }
  .grid12__desktop-col--10 {
    grid-column: span 10;
  }
  .grid12__desktop-col--9 {
    grid-column: span 9;
  }
  .grid12__desktop-col--8 {
    grid-column: span 8;
  }
  .grid12__desktop-col--7 {
    grid-column: span 7;
  }
  .grid12__desktop-col--6 {
    grid-column: span 6;
  }
  .grid12__desktop-col--5 {
    grid-column: span 5;
  }
  .grid12__desktop-col--4 {
    grid-column: span 4;
  }
  .grid12__desktop-col--3 {
    grid-column: span 3;
  }
  .grid12__desktop-col--2 {
    grid-column: span 2;
  }
  .grid12__desktop-col--1 {
    grid-column: span 1;
  }
}
.form {
  margin: 0 auto;
}
.form__button {
  margin: 0 1ch;
}
.form__section {
  border-top: 1px solid var(--brand-color-dark);
}
@media (min-width: 512px) {
  .form__section {
    display: grid;
    grid-template-columns: 21ch 1fr;
    margin: 1em 0;
  }
}
.control-group {
  margin: 0.5em 0;
  padding: 0.5em 0;
  border-left: 0.5ch solid var(--brand-color-secondary);
  background-color: var(--brand-color-lightest);
}
.control-group__hint {
  margin: 0 0.5em;
  padding: 0;
  font-size: small;
}
.control-group__error-message {
  display: none;
  color: var(--brand-color-danger);
  font-weight: bold;
}
.control-group--error .control-group__error-message {
  display: block;
}
.control {
  padding: 0.5ch;
  display: block;
  align-self: end;
  background-color: transparent;
}
.control textarea,
.control input:not([type="radio"]):not([type="checkbox"]) {
  width: 100%;
}
.control :is(input[type="checkbox"], input[type="radio"]) + label::before {
  content: "\00a0";
}
.control label:has(+ select)::after {
  content: " ";
}
.control__hint {
  margin: 0;
  padding: 0;
  font-size: small;
}
.control--required .control__label {
  font-weight: bold;
}
.button {
  color: var(--brand-color);
  background: none;
  padding: 1em 2em;
  border: 1px solid currentColor;
  transition: transform 0.1s ease-out;
}
.button:disabled,
.button--disabled {
  color: var(--brand-color-light);
  background-color: var(--brand-color-grey);
}
.button:active,
.button--active {
  transform: scale(0.95);
}
.button:hover:not(:disabled),
.button--hover {
  background-color: var(--brand-color-light);
  color: var(--brand-color-white);
}
.button--primary {
  background-color: var(--brand-color);
  color: var(--brand-color-white);
}
.button--primary:disabled {
  color: var(--brand-color);
  background-color: var(--brand-color-dark);
}
.button--primary:hover:not(:disabled),
.button--primary.button--hover,
.button--primary:focus,
.button--primary.button--focus {
  background-color: var(--brand-color-lighter);
  color: var(--brand-color-white);
}
.button--submiting,
.button--submiting:hover:not(:disabled),
.button--submiting.button--hover,
.button--submiting:focus,
.button--submiting.button--focus {
  animation: blinds 2s linear infinite;
  background-position: 0 0;
  background-size: 5ch 1em;
  background-repeat: repeat-x repeat-y;
  background-image: linear-gradient(to right, var(--brand-color) 0%, var(--brand-color-secondary) 50%, var(--brand-color) 100%);
  color: white;
}
@keyframes blinds {
  100% {
    background-position: 5ch 0;
  }
}
.button--secondary {
  background-color: var(--brand-color-secondary);
  color: var(--brand-color-white);
}
.button--secondary:disabled {
  color: var(--brand-color-secondary);
  background-color: var(--brand-color-secondary-dark);
}
.button--secondary:hover:not(:disabled),
.button--secondary.button--hover,
.button--secondary:focus,
.button--secondary.button--focus {
  background-color: var(--brand-color-secondary-lighter);
  color: var(--brand-color-white);
}
.suggest-helper {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  width: fit-content;
  background-color: var(--brand-color-white);
  border: none;
  color: var(--brand-color-black);
  transition: 0.1s ease-out;
}
.suggest-helper:active {
  transform: translateY(0.1em);
}
.suggest-helper__label {
  cursor: pointer;
  display: inline;
  color: inherit;
  background-color: inherit;
  text-align: end;
  line-height: 1.5;
  border: none;
  border-bottom: 1px dashed var(--brand-color-secondary);
}
select.suggest-helper__label {
  appearance: none;
  text-align-last: center;
  width: 11ch;
}
@media (min-width: 512px) {
  .section {
    position: relative;
    background-clip: content-box;
  }
  .section:not(:first-of-type) {
    padding-top: 1rem;
    border-top: 1px solid var(--brand-color);
  }
  .section__header {
    position: absolute;
    margin-top: 0;
    left: 0;
    top: 0;
    max-width: calc((21ch - 1ch) / 1.17);
    width: min-content;
  }
  .section:not(:first-of-type) .section__header {
    top: 1rem;
  }
  .section__content {
    margin-left: calc(21rem * 76.3 / 140);
    padding-left: 0.5ch;
    border-left: 0.5ch solid var(--brand-color-secondary);
  }
}
.header {
  font-size: 16px;
  display: grid;
  width: 100%;
  grid-template-columns: min-content auto;
  grid-gap: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 767.98px) {
  .header {
    padding-top: 5px;
  }
}
@media (max-width: 610px) {
  .header {
    grid-gap: 0;
  }
}
.logo {
  width: 350px;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 10px 20px;
  grid-template-areas: "icon text" "slogan slogan";
  align-items: center;
}
.logo__icon {
  display: block;
  padding: 0;
  grid-area: icon;
}
.logo__icon svg {
  width: 147px;
  height: 62px;
}
.logo__icon:hover {
  opacity: 0.8;
}
.logo__text {
  grid-area: text;
  color: #0c98d9;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 1.33rem;
  font-weight: normal;
  margin: 0;
  width: min-content;
}
.logo__slogan {
  grid-area: slogan;
  font-size: 1rem;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
}
@media (max-width: 767.98px) {
  .logo {
    width: auto;
  }
  .logo__slogan {
    display: none;
  }
  .logo__icon svg {
    width: calc(147px / 2);
    height: calc(62px / 2);
  }
}
@media (max-width: 610px) {
  .logo {
    grid-gap: 0 1ch;
  }
  .logo__text {
    display: none;
  }
}
@media (max-width: 365px) {
  .logo__icon svg {
    width: calc(147px / 2.8);
    height: calc(62px / 2.8);
  }
}
.contacts {
  font-size: 1rem;
  display: grid;
  grid-template-areas: "address phones" "instagram email";
  grid-template-columns: auto 18ch;
  grid-gap: 1ch;
  align-items: end;
  justify-items: start;
}
.contacts__address {
  align-self: start;
  justify-self: end;
  grid-area: address;
  text-align: end;
}
.contacts__phones {
  align-self: start;
  grid-area: phones;
  padding-top: 0.2em;
}
.contacts__phones a:not(:first-of-type) {
  display: inline-block;
  margin-top: 0.5em;
}
.contacts__working-hours {
  grid-area: working-hours;
}
.contacts__email {
  grid-area: email;
}
.contacts__instagram {
  visibility: hidden;
  justify-self: end;
  grid-area: instagram;
}
.contacts__place-selector {
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .contacts {
    margin-right: 60px;
  }
}
@media (max-width: 490px) {
  .contacts__address-text {
    display: none;
  }
}
@media (max-width: 370px) {
  .contacts__place-selector {
    font-size: 0.8rem;
  }
}
.nav-header {
  font-size: 1.11rem;
  line-height: 1.1;
  margin-bottom: 30px;
  --gradient-padding: calc((130px - 105px) / 2);
  background-image: url(../img/nav-bg.jpg), linear-gradient(to bottom, transparent 0, transparent var(--gradient-padding), var(--brand-color) var(--gradient-padding), var(--brand-color) 50%, transparent 50%, transparent), linear-gradient(to top, transparent 0, transparent var(--gradient-padding), var(--brand-color) var(--gradient-padding), var(--brand-color) 50%, transparent 50%, transparent);
  background-repeat: no-repeat;
  background-position: center center;
}
.nav-header__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.nav-header__item {
  color: white;
  padding: 0;
  flex-grow: 1;
  flex-basis: 0;
  transition: transform 0.1s ease-out;
  --link-padding: 30px;
}
.nav-header__item .nav-header__link {
  padding-left: var(--link-padding);
}
.nav-header__item:last-child .nav-header__link {
  padding-right: var(--link-padding);
}
.nav-header__icon {
  fill: currentColor;
  height: 2em;
  width: auto;
}
.nav-header__caption {
  margin-top: 0;
}
.nav-header__link {
  padding-top: 41px;
  padding-bottom: 41px;
  text-decoration: none;
  color: inherit;
  display: grid;
  grid-template-columns: min-content min-content;
  column-gap: 12px;
  align-items: center;
  margin: 3px;
}
.nav-header__item:active {
  transform: scale(0.95);
}
.nav-header__item:hover,
.nav-header__item:focus-within {
  background-color: white;
  background-image: url(../img/nav-active-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: var(--brand-color);
}
@media (max-width: 1023.98px) {
  .nav-header {
    background-image: none;
  }
  .nav-header__items {
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    gap: 10px;
  }
  .nav-header__item {
    --link-padding: 5px;
    flex-basis: 0;
    flex-grow: 0;
    flex-shrink: 0;
    height: auto;
    background-color: var(--brand-color);
  }
  .nav-header__link {
    padding: var(--link-padding);
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    margin: 3px;
  }
}
.footer {
  width: 100%;
  border-top: 2px solid #d7e2e8;
  padding: 40px 25px 60px;
  font-size: 0.89rem;
  line-height: 1.5;
}
.footer__columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}
.footer__col {
  flex-grow: 0;
  text-align: start;
}
.nav-footer {
  display: block;
  width: 100%;
  overflow: hidden;
  min-height: 50px;
}
.nav-footer__menu-switch {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.nav-footer__icon {
  margin: auto;
  stroke: var(--brand-color-secondary);
}
.nav-footer__icon-cross,
.nav-footer__icon-menu {
  display: none;
}
.nav-footer__menu-switch.nav-footer__menu-switch--open .nav-footer__icon-cross {
  display: block;
}
.nav-footer__menu-switch:not(.nav-footer__menu-switch--open) .nav-footer__icon-menu {
  display: block;
}
.nav-footer__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.nav-footer__item {
  margin: 0;
  padding: 3px;
  flex-grow: 1;
  text-align: center;
}
.nav-footer__item:hover .nav-footer__link,
.nav-footer__item:focus-within .nav-footer__link:focus-visible {
  text-decoration: none;
  color: var(--brand-color-white);
  background-color: var(--brand-color-secondary);
  background-size: 100%;
  background-image: linear-gradient(to right bottom, var(--brand-color-secondary) 0%, var(--brand-color-secondary-lighter) 100%);
}
.nav-footer__link {
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
  min-height: 50px;
  line-height: 50px;
}
@media (max-width: 767.98px) {
  .nav-footer__menu-switch {
    display: block;
  }
  .nav-footer__items {
    z-index: 10;
    display: none;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    margin-top: 50px;
    background-color: var(--brand-color-white);
    border: 2px solid var(--brand-color-secondary);
  }
  .nav-footer__items--open {
    display: flex;
  }
  .nav-footer__item {
    margin: 0;
    padding: 0;
    min-height: 0;
    text-align: start;
  }
  .nav-footer__link {
    display: block;
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1ch;
    line-height: 1.5;
    font-weight: bold;
    height: auto;
    min-height: 0;
  }
}
.accordion {
  display: flex;
  flex-direction: column;
}
.accordion__item-title {
  margin: 0;
  cursor: pointer;
  display: inline;
  border-bottom: 1px dashed currentColor;
}
.accordion__item[open] .accordion__item-title {
  border-bottom-color: transparent;
  cursor: initial;
}
.contacts-page__phone-list {
  list-style-type: none;
  padding: 0;
}
.contacts-page__phone-list-item {
  display: flex;
  gap: 1ch;
}
.contacts-page__phone-list-item:not(:first-child) {
  margin-top: 5px;
}
.contacts-page__phone-link {
  white-space: nowrap;
}
.package-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 30px;
}
.package-list__item {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2ch;
}
.package-list__figure {
  margin: 0;
}
.package-list__img {
  display: block;
  margin: 0 auto;
}
.package-list__caption {
  max-width: 20ch;
  text-align: center;
}
.pricing__table {
  border-collapse: collapse;
  border-top: 1px solid grey;
}
.pricing__directions {
  display: grid;
  gap: 10px;
}
.pricing__table-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: stretch;
  gap: 2ch;
}
.pricing__table caption {
  font-weight: bold;
  position: relative;
}
.pricing__table-measure-unit {
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: normal;
}
.pricing__table td {
  text-align: center;
}
.pricing__table-wrapper {
  page-break-inside: avoid;
}
.announcement {
  display: flex;
  margin-bottom: 1em;
}
.announcement__block {
  margin: auto;
  background-color: var(--brand-color-secondary-light);
  padding: 1em;
  border-radius: 1em;
  text-align: center;
}
.v-text {
  writing-mode: vertical-rl;
  height: min-content;
  transform: rotate(180deg);
}
@media print {
  .v-text {
    writing-mode: horizontal-tb;
    height: auto;
    transform: none;
  }
}
.nobr {
  white-space: nowrap;
}
.flex {
  display: flex;
}
.flex--wrap {
  flex-wrap: wrap;
}
.min-content {
  width: min-content;
}
.max-content {
  width: max-content;
}
.fit-content {
  width: fit-content;
}
