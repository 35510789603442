.control {
	padding: 0.5ch;
	display: block;
	align-self: end;

	background-color: transparent;
}

.control textarea,
.control input:not([type="radio"]):not([type="checkbox"]) {
	width: 100%;
}

.control :is(input[type="checkbox"], input[type="radio"]) + label::before {
	content: "\00a0";
}

.control label:has(+ select)::after {
	content: " ";
}

.control__hint {
	margin: 0;
	padding: 0;
	font-size: small;
}

.control--required .control__label {
	font-weight: bold;
}
