.grid12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

@column-count: 12;

.grid12__col {
  grid-column: span @column-count;
}

.span-loop (@i) when (@i > 0) {
  .grid12__col--@{i} {
    grid-column: span ~"@{i}";
  }
  .span-loop(@i - 1);
}
.span-loop (@column-count);

@media (min-width: @width-tablet) {
  .span-loop (@i) when (@i > 0) {
    .grid12__desktop-col--@{i} {
      grid-column: span ~"@{i}";
    }
    .span-loop(@i - 1);
  }
  .span-loop (@column-count);
}
