.package-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 30px;
}

.package-list__item {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2ch;
}

.package-list__figure {
  margin: 0;
}

.package-list__img {
  display: block;
  margin: 0 auto;
}

.package-list__caption {
  max-width: 20ch;
  text-align: center;
}
