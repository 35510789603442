.logo {
  width: 350px;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 10px 20px;
  grid-template-areas:
    "icon text"
    "slogan slogan";

  align-items: center;
}

.logo__icon {
  display: block;
  padding: 0;
  grid-area: icon;
}

.logo__icon svg {
  width: 147px;
  height: 62px;
}

.logo__icon:hover {
  opacity: 0.8;
}

.logo__text {
  grid-area: text;
  color: #0c98d9;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 1.33rem;
  font-weight: normal;
  margin: 0;
  width: min-content;
}

.logo__slogan {
  grid-area: slogan;
  font-size: 1rem;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
}

@media (max-width: @width-tablet) {
  .logo {
    width: auto;
  }

  .logo__slogan {
    display: none;
  }

  .logo__icon svg {
    width: calc(147px / 2);
    height: calc(62px / 2);
  }
}

@media (max-width: 610px) {
  .logo {
    grid-gap: 0 1ch;
  }
  .logo__text {
    display: none;
  }
}

@media (max-width: 365px) {
  .logo__icon svg {
    width: calc(147px / 2.8);
    height: calc(62px / 2.8);
  }
}
