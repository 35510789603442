.contacts-page__phone-list {
  list-style-type: none;
  padding: 0;
}

.contacts-page__phone-list-item {
  display: flex;
  gap: 1ch;
}

.contacts-page__phone-list-item:not(:first-child) {
  margin-top: 5px;
}

.contacts-page__phone-link {
  white-space: nowrap;
}
