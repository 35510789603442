.nav-footer {
  display: block;
  width: 100%;
  overflow: hidden;
  min-height: 50px;
}
.nav-footer__menu-switch {
  display: none;
  position: absolute;
  top: 0;
  right: 0;

  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.nav-footer__icon {
  margin: auto;
  stroke: var(--brand-color-secondary);
}

.nav-footer__icon-cross,
.nav-footer__icon-menu {
  display: none;
}

.nav-footer__menu-switch.nav-footer__menu-switch--open .nav-footer__icon-cross {
  display: block;
}

.nav-footer__menu-switch:not(.nav-footer__menu-switch--open)
  .nav-footer__icon-menu {
  display: block;
}

.nav-footer__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.nav-footer__item {
  margin: 0;
  padding: 3px;
  flex-grow: 1;
  text-align: center;
}

.nav-footer__item:hover .nav-footer__link,
.nav-footer__item:focus-within .nav-footer__link:focus-visible {
  text-decoration: none;
  color: var(--brand-color-white);
  background-color: var(--brand-color-secondary);
  background-size: 100%;
  background-image: linear-gradient(
    to right bottom,
    var(--brand-color-secondary) 0%,
    var(--brand-color-secondary-lighter) 100%
  );
}

.nav-footer__link {
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
  min-height: 50px;
  line-height: 50px;
}

@media (max-width: @width-tablet) {
  .nav-footer__menu-switch {
    display: block;
  }

  .nav-footer__items {
    z-index: 10;
    display: none;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    margin-top: 50px;
    background-color: var(--brand-color-white);
    border: 2px solid var(--brand-color-secondary);
  }
  .nav-footer__items--open {
    display: flex;
  }

  .nav-footer__item {
    margin: 0;
    padding: 0;
    min-height: 0;
    text-align: start;
  }

  .nav-footer__link {
    display: block;
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1ch;
    line-height: 1.5;
    font-weight: bold;
    height: auto;
    min-height: 0;
  }
}
