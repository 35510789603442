.suggest-helper {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  width: fit-content;
  background-color: var(--brand-color-white);
  border: none;
  color: var(--brand-color-black);

  transition: 0.1s ease-out;
}

.suggest-helper:active {
  transform: translateY(0.1em);
}

.suggest-helper__label {
  cursor: pointer;
  display: inline;
  color: inherit;
  background-color: inherit;
  text-align: end;
  line-height: 1.5;
  border: none;
  border-bottom: 1px dashed var(--brand-color-secondary);
}

select.suggest-helper__label {
  appearance: none;
  text-align-last: center;
  width: 11ch;
}
