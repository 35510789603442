.footer {
  width: 100%;
  border-top: 2px solid #d7e2e8;
  padding: 40px 25px 60px;
  font-size: 0.89rem;
  line-height: 1.5;
}

.footer__columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.footer__col {
  flex-grow: 0;
  text-align: start;
}
