.header {
  font-size: 16px;
  display: grid;
  width: 100%;
  grid-template-columns: min-content auto;
  grid-gap: 20px;

  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: @width-tablet) {
  .header {
    // grid-template-columns: min-content;
    padding-top: 5px;
  }
}

@media (max-width: 610px) {
  .header {
    grid-gap: 0;
  }
}
